import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  addKeyword,
  fetchKeywordsForEntity,
  updateEntityKeyword
} from "../../../../services/keywords.service";
import { STD_ERROR_MESSAGE } from "../../../../utils/constant";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";

const SectionKeywords = () => {
  const [inputText, setInputText] = useState("");

  const keywordState = useSelector((state) => state.keywordsEm);

  const dispatch = useDispatch();
  const params = useParams();

  const keywordsData = keywordState?.response.data;

  useEffect(() => {
    if (isEmpty(keywordsData)) {
      dispatch(fetchKeywordsForEntity(params.entityId));
    }
  }, []);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleAddWord = async () => {
    const payload = {
      keyword: inputText
    };
    addKeyword(payload, params.entityId)
      .then((res) => {
        if (res.error) {
          toast.error(res.error ? res.error_data : STD_ERROR_MESSAGE);
          return;
        }
        dispatch(fetchKeywordsForEntity(params.entityId));
        toast.success("Keyword saved successfully");
        setInputText("");
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddWord();
    }
  };

  const handleDeleteWord = async (keywordId) => {
    updateEntityKeyword(params.entityId, keywordId)
      .then((res) => {
        if (res.error) {
          toast.error(res.error ? res.error_data : STD_ERROR_MESSAGE);
          return;
        }
        dispatch(fetchKeywordsForEntity(params.entityId));
        toast.success("Keyword deleted successfully");
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <h3 style={{ marginBottom: "2rem" }}>Add Keyword</h3>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Box
            style={{
              border: "1px solid var(--information-color)",
              background: "white",
              borderRadius: "4px",
              padding: "0.27rem",
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <input
              type="text"
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Enter Keyword"
              style={{
                minWidth: "200px",
                border: "none",
                outline: "none",
                margin: "0.25rem"
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <ButtonAccent label="Add" type="submit" onClick={handleAddWord}>
            Add
          </ButtonAccent>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "1rem",
          gap: "1.5rem"
        }}
      >
        {keywordsData?.map((word, index) => (
          <Box
            key={index}
            style={{
              background: "var(--accent-color)",
              borderRadius: "4px",
              padding: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Poppins",
              gap: "1rem"
            }}
          >
            <span>{word.keyword}</span>
            <div
              onClick={() => handleDeleteWord(word.id)}
              style={{ fontWeight: "2px", cursor: "pointer" }}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "1.2rem",
                  color: "rgba(0, 0, 0, 0.7)"
                }}
              />
            </div>
          </Box>
        ))}
      </div>
    </main>
  );
};

export default SectionKeywords;
