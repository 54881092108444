import {
  CLEAR_FETCH_FILTERED_ENTITY_AD_STATES,
  FETCH_FILTERED_ENTITY_AD_FAILURE,
  FETCH_FILTERED_ENTITY_AD_SUCCESS,
  REQUEST_FETCH_FILTERED_ENTITY_AD
} from "./filtered.entities.types";

export const requestFetchFilteredEntityAd = () => {
  return {
    type: REQUEST_FETCH_FILTERED_ENTITY_AD
  };
};

export const fetchFilteredEntityAdSuccess = (response) => {
  return {
    type: FETCH_FILTERED_ENTITY_AD_SUCCESS,
    payload: response
  };
};

export const fetchFilteredEntityAdFailure = (error) => {
  return {
    type: FETCH_FILTERED_ENTITY_AD_FAILURE,
    payload: error
  };
};

export const clearFetchFilteredEntityAdStates = () => {
  return {
    type: CLEAR_FETCH_FILTERED_ENTITY_AD_STATES
  };
};
