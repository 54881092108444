import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/layout/navbar/Navbar";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import ContentArea from "../../../components/layout/content-area/ContentArea";
import Sidebar from "../../../components/layout/sidebar/Sidebar";

import { getACollection } from "../../../services/collections.service.js";
import { clearFetchCollectionEmStates } from "../../../redux/collection/collection.action";
import { clearFetchKnowTheHostSectionsEmStates } from "../../../redux/know-the-host/knowTheHost.action";
import { clearFetchInvitesEmStates } from "../../../redux/invites/invites.action";
import {
  GUEST_COMPONENTS,
  STD_ERROR_MESSAGE,
  WEDDING_FOLDERS,
} from "../../../utils/constant.js";
import SectionInvites from "../../../components/layout/wedding-sections/section-invites/SectionInvites";
import SectionKnowTheCouple from "../../../components/layout/wedding-sections/section-know-the-couple/SectionKnowTheCouple";
import SectionItinerary from "../../../components/layout/wedding-sections/section-itinerary/SectionItinerary";
import SectionLiveFeed from "../../../components/layout/wedding-sections/section-live-feed/SectionLiveFeed";
import SectionPhotoGallery from "../../../components/layout/wedding-sections/section-photo-gallery/SectionPhotoGallery";
import { clearFetchItineraryEmStates } from "../../../redux/itinerary/itinerary.action";
import { clearFetchGalleryEmStates } from "../../../redux/gallery/gallery.action";
import { clearFetchGalleryFoldersEmStates } from "../../../redux/gallery-folders/galleryFolders.action";
import { clearFetchLiveFeedsEmStates } from "../../../redux/live-feed/liveFeed.action";
import { clearFetchGuestInvitesEmStates } from "../../../redux/guest-invites/guestInvites.action";
import { clearFetchQuestionsEmStates } from "../../../redux/games/games.action";
import { clearFetchVenueEmStates } from "../../../redux/venue/venue.action";
import { getAllGallery } from "../../../services/gallery.service";
import GuestInviteList from "../../../components/layout/guest-invite-list/GuestInviteList";
import SectionGames from "../../../components/layout/wedding-sections/section-games/SectionGames";
import SectionVenue from "../../../components/layout/wedding-sections/section-venue/SectionVenue";
import SectionNotification from "../../../components/layout/wedding-sections/section-notification/SectionNotification";
import SectionFaceRecognition from "../../../components/layout/wedding-sections/section-face-recognition/SectionFaceRecognition.js";
import SectionPreferences from "../../../components/layout/wedding-sections/section-prferences/SectionPreferences";
import SectionResidents from "../../../components/layout/entity-sections/section-residents/SectionResidents.jsx";
import SectionNews from "../../../components/layout/entity-sections/section-news/SectionNews.jsx";
import SectionPolicies from "../../../components/layout/entity-sections/section-policies/SectionPolicies.jsx";
import { clearFetchPoliciesAdStates } from "../../../redux/policies/policies.action.js";
import { clearFetchresidentsAdStates } from "../../../redux/residents/residents.action.js";
import { clearFetchNewsAdStates } from "../../../redux/news/news.action.js";
import AddDescription from "../../../components/layout/add-description-community/AddDescription.js";
import SectionEventPermalink from "../../../components/layout/wedding-sections/section-event-permalink/SectionEventPermalink.jsx";
import { clearFetchEventPermalinkEmStates } from "../../../redux/event-permalink/eventPermalink.action.js";
import SectionLvhGroups from "../../../components/layout/entity-sections/section-lvh-groups/SectionLvhGroups.js";
import SectionMeetings from "../../../components/layout/wedding-sections/section-meetings/SectionMeetings.jsx";
import { clearFetchMeetingStates } from "../../../redux/meeting/meeting.action.js";
import SectionKeywords from "../../../components/layout/wedding-sections/section-keywords/SectionKeyword.jsx";
import { clearFetchKeywordsEmStates } from "../../../redux/keywords/keywords.action.js";

const Collection = (props) => {
  const [loading, setLoading] = useState({ gallery: true, collection: true });

  const params = useParams();

  const collectionState = useSelector((state) => state.collectionEm);
  const galleryState = useSelector((state) => state.galleryEm);

  const collectionData = collectionState?.response?.data;
  const [selected, setSelected] = useState({ folder: null, subFolder: null });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(collectionState.response)) {
      dispatch(getACollection(params?.collectionId));
    }
    if (isEmpty(galleryState.response)) {
      dispatch(getAllGallery(params?.collectionId));
    }
    return () => {
      dispatch(clearFetchCollectionEmStates());
      dispatch(clearFetchKnowTheHostSectionsEmStates());
      dispatch(clearFetchInvitesEmStates());
      dispatch(clearFetchItineraryEmStates());
      dispatch(clearFetchGalleryEmStates());
      dispatch(clearFetchGalleryFoldersEmStates());
      dispatch(clearFetchLiveFeedsEmStates());
      dispatch(clearFetchGuestInvitesEmStates());
      dispatch(clearFetchQuestionsEmStates());
      dispatch(clearFetchVenueEmStates());
      dispatch(clearFetchNewsAdStates());
      dispatch(clearFetchresidentsAdStates());
      dispatch(clearFetchPoliciesAdStates());
      dispatch(clearFetchEventPermalinkEmStates());
      dispatch(clearFetchMeetingStates());
      dispatch(clearFetchKeywordsEmStates());
    };
  }, []);

  useEffect(() => {
    if (!collectionState.loading && !isEmpty(collectionState.response)) {
      setLoading((ps) => {
        return {
          ...ps,
          collection: false,
        };
      });
      if (selected.folder == null) {
        //only set the first time
        setSelected((ps) => {
          return {
            ...ps,
            folder:
              collectionData?.category_id == "t" ||
              collectionData?.category_id == "r"
                ? WEDDING_FOLDERS.home
                : WEDDING_FOLDERS.invites,
          };
        });
      }
    }

    if (!collectionState.loading && !isEmpty(collectionState.error)) {
      setLoading((ps) => {
        return {
          ...ps,
          collection: false,
        };
      });
      toast.error(collectionState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [collectionState]);

  useEffect(() => {
    if (!galleryState.loading && !isEmpty(galleryState.response)) {
      setLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
    }

    if (!galleryState.loading && !isEmpty(galleryState.error)) {
      setLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
      toast.error(galleryState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [galleryState]);

  function getMainComponent() {
    switch (selected.folder) {
      case WEDDING_FOLDERS.invites:
        return <SectionInvites setSelected={setSelected} />;
      case WEDDING_FOLDERS.know_the_couple:
      case WEDDING_FOLDERS.associates:
        return (
          <SectionKnowTheCouple
            setSelected={setSelected}
            category={collectionData.category_id}
          />
        );
      case WEDDING_FOLDERS.itinerary:
        return <SectionItinerary setSelected={setSelected} />;
      case WEDDING_FOLDERS.live_feed:
      case WEDDING_FOLDERS.feed:
        return <SectionLiveFeed setSelected={setSelected} />;
      case WEDDING_FOLDERS.photos_gallery:
      case WEDDING_FOLDERS.events:
        return (
          <SectionPhotoGallery selected={selected} setSelected={setSelected} />
        );
      case WEDDING_FOLDERS.master_invite_list:
        return <GuestInviteList component={GUEST_COMPONENTS.master} />;
      case WEDDING_FOLDERS.games:
        return <SectionGames />;
      case WEDDING_FOLDERS.venue:
        return <SectionVenue setSelected={setSelected} />;
      case WEDDING_FOLDERS.notification:
        return <SectionNotification />;
      case WEDDING_FOLDERS.face_recognition:
        return <SectionFaceRecognition />;
      case WEDDING_FOLDERS.preferences:
        return <SectionPreferences />;
      case WEDDING_FOLDERS.residents:
        return <SectionResidents category={collectionData.category_id} />;
      case WEDDING_FOLDERS.description:
        return <AddDescription />;
      case WEDDING_FOLDERS.home:
        return <SectionNews />;
      case WEDDING_FOLDERS.policies:
        return <SectionPolicies />;
      case WEDDING_FOLDERS.permalink:
        return <SectionEventPermalink />;
      case WEDDING_FOLDERS.team:
        return <SectionLvhGroups />;
      case WEDDING_FOLDERS.meetings:
        return (
          <SectionMeetings selected={selected} setSelected={setSelected} />
        );
      case WEDDING_FOLDERS.keywords:
        return <SectionKeywords />;
      default:
        return null;
    }
  }

  return (
    <>
      <Navbar />
      <ContentArea>
        <Grid container sx={{ height: "100%" }}>
          {/* sidebar */}
          <Grid
            item
            sx={{
              height: "100%",
              display: {
                xs: "none",
                sm: "inherit",
              },
            }}
            sm={4}
            md={3}
            lg={3}
            xl={3}
          >
            <Sidebar
              loading={loading}
              selected={selected}
              setSelected={setSelected}
            />
          </Grid>
          {/* main */}
          <Grid
            item
            sx={{
              height: "100%",
              padding: "1.5rem 2.5rem",
              overflow: "auto",
            }}
            xs={12}
            sm={8}
            md={9}
            lg={9}
            xl={9}
          >
            {getMainComponent()}
          </Grid>
        </Grid>
      </ContentArea>
    </>
  );
};

export default Collection;
