import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  getAllEntityFeeds,
  entityLiveFeedLikeOrUnlikePromise,
  deleteEntityLiveFeedByIdPromise,
} from "../../../services/liveFeed.service";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import CustomSpinner from "../../common/spinner/CustomSpinner";
import { Box } from "@mui/system";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import DeletePopup from "../delete-popup/DeletePopup";
import LiveFeedItemEntity from "../live-feed-item/LiveFeedItemEntity";
import EntityLiveFeedCreateOrEdit from "../entity-live-feed-create-or-edit/EntityLIveFeedCreateOrEdit";
import moment from "moment/moment";

const EntityUserLiveUpdates = (props) => {
  const [loading, setLoading] = useState(true);
  const { entityId } = props;

  const liveFeedsState = useSelector((state) => state.liveFeedEm);
  const entityState = useSelector((state) => state.entityAd);

  const dispatch = useDispatch();

  const liveFeedsData = liveFeedsState?.response?.data?.live_feed_response;
  const entityData = entityState.response?.data;
  const enableData = liveFeedsState?.response?.data?.enable_user_post;
  const [liveFeed, setLiveFeed] = useState(false);

  const [deletePopup, setDeletePopup] = useState({
    open: false,
    id: null,
  });

  useEffect(() => {
    if (isEmpty(liveFeedsState?.response)) {
      dispatch(getAllEntityFeeds(entityId));
    }
  }, []);

  useEffect(() => {
    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.response)) {
      setLoading(false);
    }

    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.error)) {
      setLoading(false);
      toast.error(liveFeedsState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [liveFeedsState]);

  function handleLiveFeedLike(id, currLikeState) {
    entityLiveFeedLikeOrUnlikePromise(entityId, id, {
      action: currLikeState ? "dislike" : "like",
    })
      .then((res) => {
        dispatch(getAllEntityFeeds(entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  function handleDeletePopupClick(id) {
    setDeletePopup({
      open: true,
      id,
    });
  }

  function closeDeletePopup() {
    setDeletePopup({
      open: false,
      id: null,
    });
  }

  const onClick = () => {
    setLiveFeed(true);
    if (window.scrollY) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  function handleDeleteFeed() {
    deleteEntityLiveFeedByIdPromise(entityId, deletePopup.id)
      .then((res) => {
        setDeletePopup({
          open: false,
          id: null,
        });
        dispatch(getAllEntityFeeds(entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }
  return (
    <>
      <div
        style={{
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "1rem",
          paddingBottom: "2rem",
          minHeight: "calc(100vh - 64px - 56px - 40px)",
        }}
      >
        {loading && <CustomSpinner />}
        {liveFeed && (
          <EntityLiveFeedCreateOrEdit
            action="create"
            entityId={entityData?.id}
            handleClose={() => setLiveFeed(false)}
          />
        )}
        {!loading && !isEmpty(liveFeedsData) ? (
          liveFeedsData.map((obj) => (
            <LiveFeedItemEntity
              key={obj?.live_feed?.id}
              thumbnail={getImageUrl(entityData?.image?.bucket_path)}
              title={entityData?.name}
              contentText={obj?.live_feed?.text}
              createdAt={moment(obj?.live_feed?.image?.created_at).format(
                "MMMM Do YYYY"
              )}
              contentImageUrl={getImageUrl(obj?.live_feed?.image?.bucket_path)}
              onDeletePostClick={() =>
                handleDeletePopupClick(obj?.live_feed?.id)
              }
              onLikeClick={() =>
                handleLiveFeedLike(obj?.live_feed?.id, obj?.liked_by_user)
              }
              likesCount={obj?.live_feed?.likes_count}
              likeState={obj?.liked_by_user}
            />
          ))
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
            There are no feeds available.
          </h4>
        )}
      </div>
      {!loading && enableData == true && (
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Box>
            <div
              style={{
                position: "fixed",
                marginBottom: "0",
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "4rem",
                backgroundColor: "white",
                padding: "0.5rem 0.5rem",
                bottom: "0rem",
              }}
            >
              <ButtonAccent
                variant="span-button"
                customStyling={{ margin: "0 auto" }}
                startSpan="+"
                startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                onClick={onClick}
                type="submit"
                label="Add Post"
              />
            </div>
          </Box>
        </Box>
      )}
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE POST"
          text="This post will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteFeed}
          handleClose={closeDeletePopup}
        />
      )}
    </>
  );
};

export default EntityUserLiveUpdates;
