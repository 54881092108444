import {
  fetchFilteredEntityAdFailure,
  fetchFilteredEntityAdSuccess,
  requestFetchFilteredEntityAd
} from "../redux/filtered-entities/filtered.entities.action";
import {
  fetchKeywordsEmFailure,
  fetchKeywordsEmSuccess,
  requestFetchKeywordsEm
} from "../redux/keywords/keywords.action";

import ProtectedWebClient from "../utils/protected-web-client";

export const addKeyword = (payload, entityId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/keyword`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const fetchKeywordsForEntity = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchKeywordsEm());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/keyword`
      );
      dispatch(fetchKeywordsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchKeywordsEmFailure(error?.response));
    }
  };
};

export const updateEntityKeyword = (entityId, keywordId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/entity/${entityId}/keyword/${keywordId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

//For search
export const getAllKeywords = (keyword) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchKeywordsEm());
      const response = await ProtectedWebClient.get(
        `/keywords/search?keyword=${keyword}`
      );
      dispatch(fetchKeywordsEmSuccess(response?.data));
    } catch (error) {
      dispatch(fetchKeywordsEmFailure(error?.response));
    }
  };
};

export const getAllEntitiesByKeyword = (keyword) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchFilteredEntityAd());
      const response = await ProtectedWebClient.get(`/keyword/${keyword}`);
      dispatch(fetchFilteredEntityAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchFilteredEntityAdFailure(error?.response));
    }
  };
};
