import "./infoscreen.css";

const ContactUs = () => {
  return (
    <div className="container">
      <h5 className="heading">Contact Us</h5>
      <p className="pr16-lh24">
        For any queries, reach out to{" "}
        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=suneel@trueco.biz"
          rel="noreferrer"
          target="_blank"
        >
          suneel@trueco.biz
        </a>{" "}
        or call +91 9886 283 665
      </p>
      <p className="pr16-lh24">
        Alternatively, share your details{" "}
        <a
          href="https://forms.gle/UeJwVKZLgUKJGN3A9"
          rel="noreferrer"
          target="_blank"
        >
          here
        </a>
        , and we'll get back to you.
      </p>
    </div>
  );
};

export default ContactUs;
