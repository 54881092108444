import {
  CLEAR_FETCH_KEYWORDS_EM_STATES,
  FETCH_KEYWORDS_EM_FAILURE,
  FETCH_KEYWORDS_EM_SUCCESS,
  REQUEST_KEYWORDS_FETCH_EM,
} from "./keywords.types";

export const requestFetchKeywordsEm = () => {
  return {
    type: REQUEST_KEYWORDS_FETCH_EM,
  };
};

export const fetchKeywordsEmSuccess = (response) => {
  return {
    type: FETCH_KEYWORDS_EM_SUCCESS,
    payload: response,
  };
};

export const fetchKeywordsEmFailure = (error) => {
  return {
    type: FETCH_KEYWORDS_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchKeywordsEmStates = () => {
  return {
    type: CLEAR_FETCH_KEYWORDS_EM_STATES,
  };
};
