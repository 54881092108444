import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, size } from "lodash";

import UploadFiles from "../upload-files/UploadFiles";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";

import {
  SECTION_PHOTO_GALLERY_COMPONENTS,
  BUCKET_PATH,
  AD_BUCKET_PATH
} from "../../../utils/constant";
import { setUploadFiles } from "../../../redux/upload-files/uploadFiles.action";
import { sanitizeFileName } from "../../../utils/utils";

const UploadEntityGallery = (props) => {
  const { currGalleryFolderInfo } = props;

  const uploadFilesState = useSelector((state) => state.uploadFiles);
  const dispatch = useDispatch();

  const params = useParams();

  const handleDrop = (acceptedFiles) => {
    let lastId = null;
    if (!isEmpty(uploadFilesState.fileProgress)) {
      const ids = Object.keys(uploadFilesState.fileProgress);
      lastId = Math.max(...ids);
    }

    const payload = [];
    for (let i = 0; i < size(acceptedFiles); i++) {
      const file = acceptedFiles[i];
      payload.push({
        file: file,
        sequence: lastId ? lastId + i + 1 : i + 1,
        payload: [
          {
            bucket_path: `${AD_BUCKET_PATH.entity}/${params.entityId}/${
              AD_BUCKET_PATH.photoGallery
            }/${props.currGalleryFolderInfo.title}/${sanitizeFileName(
              file.name
            )}`,
            image_name: sanitizeFileName(file.name)
          }
        ]
      });
    }

    dispatch(setUploadFiles(payload));
    props.changeNextComponent(
      SECTION_PHOTO_GALLERY_COMPONENTS.uploading_photo_gallery
    );
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`Add Photos to ${currGalleryFolderInfo.title}`}
        cancelBtn={true}
        handleCancelBtnClick={props.onCancelClick}
      />
      <div style={{ marginTop: "20%" }}>
        <UploadFiles
          heading="Upload photos here"
          subHeading="JPEG files upto 50MB each"
          buttonLabel="Choose photos to upload"
          handleDropEvent={handleDrop}
        />
      </div>
    </main>
  );
};

export default UploadEntityGallery;
