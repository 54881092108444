import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import {
  getACollection,
  updateCollection
} from "../../../services/collections.service";
import { Field, Form, Formik } from "formik";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import errorInfoIcon from "../../../assets/icon/info-red.svg";
import editIcon from "../../../assets/icon/edit.svg";
import { renderTextWithHyperlinks } from "../../../utils/utils";

const AddDescription = () => {
  const collectionState = useSelector((state) => state.collectionEm);
  const collectionData = collectionState.response?.data;
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!collectionData?.invitation_title) {
      setDescriptionEdit(true);
    }
  }, []);

  const initialValues = {
    invitation_title: collectionData?.invitation_title
      ? collectionData?.invitation_title
      : ""
  };

  const validationSchema = Yup.object().shape({
    invitation_title: Yup.string().required("Please enter caption/body text")
  });

  function onSubmit(values) {
    updateCollection(values, collectionData.id)
      .then((res) => {
        dispatch(getACollection(collectionData.id));
        setDescriptionEdit(false);
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner />
      <div
        className="p-3 mb-3"
        style={{
          border: "1px solid rgba(41, 41, 41, 0.38)",
          borderRadius: "8px"
        }}
      >
        {descriptionEdit ? (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="pb18-lh32 high-emphasis-color mb-0">
                    Type Home Screen Content here...
                  </h3>
                  <div>
                    {collectionData?.invitation_title && (
                      <ButtonPrimary
                        label="Cancel"
                        onClick={() => setDescriptionEdit(false)}
                        className="mr-3"
                      />
                    )}
                    <ButtonAccent label="Save" type="submit" />
                  </div>
                </div>
                <Field name="invitation_title">
                  {(props) => {
                    const { field, form, meta } = props;
                    return (
                      <div className="mt-3">
                        <textarea
                          placeholder="Type caption / body text here..."
                          className="img-description-textarea pr16-lh24"
                          style={{
                            border: "none",
                            resize: "none",
                            height: "135px"
                          }}
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                            <img src={errorInfoIcon} alt="" />
                            <span className="margin-left-5 pr10-lh15 error-color">
                              {meta.error}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    );
                  }}
                </Field>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="pb18-lh32 high-emphasis-color mb-0">
                Home Screen Content
              </h3>
              <img
                style={{
                  width: "1.25rem",
                  height: "1.25rem"
                }}
                src={editIcon}
                alt=""
                className="ml-2 pt-cursor"
                onClick={() => setDescriptionEdit(true)}
              />
            </div>
            <p className="pr16-lh24 mt-3 mb-0">
              {collectionData?.invitation_title && (
                <div
                  className="whitespace-pre-wrap break-words"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {renderTextWithHyperlinks(collectionData?.invitation_title)}
                </div>
              )}
            </p>
          </>
        )}
      </div>
    </main>
  );
};
export default AddDescription;
