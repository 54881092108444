import React from "react";
import { useParams } from "react-router-dom";
import { size } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import UploadFiles from "../upload-files/UploadFiles";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { setUploadFiles } from "../../../redux/upload-files/uploadFiles.action";
import {
  AD_BUCKET_PATH,
  SECTION_ENTITY_PROFILE_COMPONENTS
} from "../../../utils/constant.js";
import { sanitizeFileName } from "../../../utils/utils.js";

const UploadLocation = (props) => {
  const entityProfileState = useSelector((state) => state.entityProfileAd);
  const uploadFilesState = useSelector((state) => state.uploadFiles);

  const dispatch = useDispatch();

  const params = useParams();

  const handleDrop = (acceptedFiles) => {
    let lastId = null;
    if (!isEmpty(uploadFilesState.fileProgress)) {
      const ids = Object.keys(uploadFilesState.fileProgress);
      lastId = Math.max(...ids);
    } else if (!isEmpty(entityProfileState.response.data)) {
      const ids = entityProfileState.response.data.map((item) => item.sequence);
      lastId = Math.max(...ids);
    }

    const payload = [];
    for (let i = 0; i < size(acceptedFiles); i++) {
      const file = acceptedFiles[i];
      payload.push({
        file: file,
        sequence: lastId ? lastId + i + 1 : i + 1,
        payload: [
          {
            bucket_path: `${AD_BUCKET_PATH.entity}/${params.entityId}/${
              AD_BUCKET_PATH.profile
            }/${sanitizeFileName(file.name)}`,
            image_name: sanitizeFileName(file.name)
          }
        ]
      });
    }
    dispatch(setUploadFiles(payload));
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.uploading_location,
        prevComponent: prevState.currComponent
      };
    });
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Add Carousel Image"
        cancelBtn={true}
        handleCancelBtnClick={props.onCancelClick}
      />
      <div style={{ marginTop: "20%" }}>
        <UploadFiles
          heading="Upload Carousel Image here"
          subHeading="JPEG files upto 50MB each"
          buttonLabel="Choose Carousel Img to upload"
          handleDropEvent={handleDrop}
        />
      </div>
    </main>
  );
};

export default UploadLocation;
