export const WEDDING_FOLDERS = {
  invites: "invites",
  know_the_couple: "know_the_couple",
  itinerary: "itinerary",
  live_feed: "live_feed",
  photos_gallery: "photos_gallery",
  master_invite_list: "master_invite_list",
  games: "games",
  venue: "venue",
  notification: "notification",
  face_recognition: "face_recognition",
  rsvp: "RSVP",
  preferences: "preferences",
  home: "home",
  associates: "associates",
  residents: "residents",
  feed: "feed",
  events: "events",
  policies: "policies",
  description: "description",
  permalink: "permalink",
  team: "team",
  keywords: "keywords",
  settings: "settings"
};

export const BNI_CHAPTER_FOLDERS = {
  LVH: "LVH",
  USER: "USER",
  LT: "LT"
};

export const getLocalDateString = (date) => {
  if (date == null) {
    return "";
  }
  return new Date(date)?.toISOString().split("T")[0];
};

export const getLocalTimeString = (date) => {
  if (date == null) {
    return "";
  }
  return new Date(date)?.toTimeString().slice(0, 5);
};

export const getCategory = (category) => {
  switch (category) {
    case "t":
      return "Add Residents";
    case "r":
      return "Add Members";
    case "w":
      return "Add Couple Story";
    default:
      return "";
  }
};

export const getCategories = (categories) => {
  switch (categories) {
    case "t":
      return "Add Associates";
    case "r":
      return "Add LT/MC";
    case "w":
      return "About";
    default:
      return "";
  }
};

export const ENTITY_FOLDERS = {
  group: "group",
  profile: "profile",
  know_the_entity: "know_the_entity",
  live_feed: "live_feed",
  photos_gallery: "photos_gallery",
  testimonial: "testimonial",
  permalink: "permalink",
  contactUs: "contactUs",
  keywords: "keywords"
};

export const SECTION_INVITES_COMPONENTS = {
  upload_invites: "upload_invites",
  invites_list: "invites_list",
  uploading_invites: "uploading_invites",
  master_invite_list: "master_invite_list",
  invite_guest_list: "invite_guest_list"
};

export const SECTION_ENTITY_PROFILE_COMPONENTS = {
  upload_location: "upload_location",
  location_list: "location_list",
  uploading_location: "uploading_location"
};

export const SECTION_VENUE_COMPONENTS = {
  add_venue: "add_venue",
  venue_list: "venue_list",
  edit_venue: "edit_venue",
  master_invite_list: "master_invite_list"
};

export const SECTION_ITINERARY_COMPONENTS = {
  upload_itinerary: "upload_itinerary",
  itinerary_list: "itinerary_list",
  uploading_itinerary: "uploading_itinerary",
  itinerary_guest_list: "itinerary_guest_list"
};

export const GUEST_COMPONENTS = {
  master: "master",
  itinerary: "itinerary",
  invite: "invite"
};

export const SECTION_PHOTO_GALLERY_COMPONENTS = {
  upload_photo_gallery: "upload_photo_gallery",
  photo_gallery_list: "photo_gallery_list",
  uploading_photo_gallery: "uploading_photo_gallery"
};

export const SECTION_GAMES_COMPONENTS = {
  saved_questions: "saved_questions",
  add_questions: "add_questions"
};
export const SECTION_RSVP_COMPONENETS = {
  respond_date: "respond_date"
};
export const SECTION_PEFERENCES_COMPONENTS = {
  saved_questions: "saved_questions",
  add_questions: "add_questions"
};

export const BUCKET_PATH = {
  know_the_host: "know_the_host",
  profile: "profile",
  collection: "collection",
  invites: "invites",
  itinerary: "itinerary",
  photoGallery: "photo_gallery",
  liveFeed: "live_feed",
  games: "games",
  user: "user",
  venue: "venue",
  rsvp: "rsvp",
  preferences: "preferences",
  news: "news",
  residents: "residents"
};

export const AD_BUCKET_PATH = {
  know_the_entity: "know_the_entity",
  profile: "profile",
  logo: "logo",
  entity: "entity",
  photoGallery: "photo_gallery",
  liveFeed: "live_feed",
  testimonial: "testimonial"
};

export const SECTION_KNOW_THE_HOST_COMPONENTS = {
  list_know_the_host_sections: "list_know_the_host_sections",
  add_know_the_host_sections: "add_know_the_host_sections"
};

export const SECTION_RESIDENTS_COMPONENTS = {
  list_residents_sections: "list_residents_sections",
  add_residents_sections: "add_residents_sections"
};

export const SECTION_NEWS_COMPONENTS = {
  list_news_sections: "list_news_sections",
  add_news_sections: "add_news_sections"
};

export const SECTION_GAMES_USER_COMPONENTS = {
  welcome: "welcome",
  question: "question",
  leaderBoard: "leader_board",
  caution: "caution"
};
export const IMAGE_VARIANT = {
  processed_2000: "processed_2000",
  processed_4000: "processed_4000",
  original: "original"
};

export const SIGN_IN_OPTIONS = {
  emailSignIn: "email-sign-in",
  emailSignUp: "email-sign-up",
  phoneSignIn: "phone-sign-in",
  googleSignIn: "google-sign-in"
};

export const ROLE_CATEGORIES = {
  admin: "a",
  eventManager: "e",
  other: "o"
};

export const EVENT_TYPES = {
  wedding: "w",
  birthday: "b",
  event: "e",
  apartment: "t",
  party: "p",
  conference: "c",
  bnichapter: "r"
};

export const ENTITY_TYPES = {
  education: "e",
  events_celebrations: "c",
  mnc_hr: "h",
  food_hospitality: "f",
  marketing: "m",
  property: "p",
  health_wellness: "w",
  business_owners: "b",
  finance: "a",
  wedding_planners: "wp",
  photographers: "pg",
  bakers_chocolatiers: "bc",
  makeup_stylists: "ms",
  resorts: "r",
  caterers: "ca",
  fashion: "fa",
  travel: "t",
  artists: "ar",
  restraunts: "res",
  florists: "fl",
  gifting: "gi",
  jewelry: "je"
};

export const STORAGE_KEYS = {
  userId: "user_id",
  authToken: "auth_token",
  userRole: "user_role",
  userName: "user_name",
  userStudioName: "user_enterprise_name",
  partnerType: "partner_type",
  originalUrl: "original_url"
};

export const STD_ERROR_MESSAGE = "Something went wrong!";

export const DEV_CLIENT_URL = "https://staging.trueco.biz";
export const DEV_SERVER_URL = "https://staging-api.trueco.biz";

export const LOCAL_CLIENT_URL = "http://localhost:3000";
export const LOCAL_SERVER_URL = "http://localhost:8080";

export const PROD_CLIENT_URL = "https://trueco.biz";
// export const PROD_SERVER_URL = "https://api.pic-sea.com"
export const PROD_SERVER_URL = "https://api.trueco.biz";

export const PROD_BASE_IMAGE_URL = "https://d3silbkha8k60d.cloudfront.net";
export const DEV_BASE_IMAGE_URL = "https://d3go26z5q6k897.cloudfront.net";

export const AD_GOOGLE_FORM_URL = "https://forms.gle/eAGwt4vgMBvvMUue8";

export const DEV_GUEST_FIREBASE_ID = "J8h0qQHWVMM58kB9mElE3FG0lyI3";
export const PROD_GUEST_FIREBASE_ID = "rZ4abb0sg8WuHkd63E3ENy7qpBQ2";

export const GUEST_MOBILE = "+911234567890";
