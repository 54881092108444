import SearchIcon from "@mui/icons-material/Search";
import {
  alpha,
  Autocomplete,
  styled,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEntitiesByKeyword,
  getAllKeywords
} from "../../../services/keywords.service";
import { setKeyword } from "../../../utils/utils";

const SearchTag = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 20,
  width: "40%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const Search = () => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const [options, setOptions] = useState([]);

  const keywordState = useSelector((state) => state.keywordsEm);

  const dispatch = useDispatch();

  const keywordsData = keywordState?.response.data;

  const handleSubmit = async (keyword) => {
    setKeyword(keyword);
    dispatch(getAllEntitiesByKeyword(keyword));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event.target.value);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (selectedOption === inputValue) {
      handleSubmit(selectedOption);
    }
  }, [selectedOption, inputValue, handleSubmit]);

  // Handle click event to fetch data immediately
  const handleSearchClick = () => {
    dispatch(getAllKeywords(inputValue));
  };

  // Debouncing for efficient API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputValue.trim()) {
        dispatch(getAllKeywords(inputValue));
      }
    }, 300); // 300ms debounce

    return () => clearTimeout(timer); // Cleanup debounce timer
  }, [inputValue, dispatch]);

  useEffect(() => {
    if (isEmpty(keywordsData)) {
      setOptions([]);
    } else {
      setOptions(keywordsData.map((option) => option.Keyword));
    }
  }, [keywordsData]);

  return (
    <SearchTag>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        sx={{ minWidth: { md: "15rem", xs: "4rem" }, marginLeft: 4 }}
        options={options}
        value={selectedOption}
        onChange={(_, newValue) => {
          setSelectedOption(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={isMobile ? "" : "Enter keyword"}
            onFocusCapture={handleSearchClick}
            sx={{
              maxWidth: { xs: "5.5rem", sm: "12rem" },
              marginLeft: { xs: 2, sm: 4 },
              "& .MuiInputBase-root": {
                color: "white"
              }
            }}
            slotProps={{
              input: {
                ...params.InputProps,
                type: "search"
              }
            }}
            onKeyDown={handleKeyDown} // Handle Enter key
          />
        )}
      />
    </SearchTag>
  );
};

export default Search;
