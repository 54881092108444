import { combineReducers } from "redux";

import PhotographerAuthenticationReducer from "./partner-authentication/partnerAuthentication.reducer";
import UploadFilesReducer from "./upload-files/uploadFiles.reducer";

//v2
import attendanceForSingleMemberReducer from "./attendance-summary-for-single-member/attendanceSummaryForSingleMember.reducer";
import attendanceSummaryMemberReducer from "./attendance-summary-member/attendanceMember.reducer";
import attendanceSummaryVisitorReducer from "./attendance-summary-visitor/attendanceSummaryVisitor.reducer";
import collectionEmReducer from "./collection/collection.reducer";
import collectionsEmReducer from "./collections/collections.reducer";
import contactsEmReducer from "./contacts/contact.reducer";
import entitiesAdReducer from "./entities/entities.reducer";
import fetchEntityProfileAdReducer from "./entity-location/entity.location.reducer";
import entityAdReducer from "./entity/entity.reducer";
import eventPermalinkEmReducer from "./event-permalink/eventPermaliknk.reducer";
import galleryFoldersEmReducer from "./gallery-folders/galleryFolders.reducer";
import myPhotosGalleryFoldersEmReducer from "./gallery-folders/myPhotosGalleryFolders.reducer";
import galleryEmReducer from "./gallery/gallery.reducer";
import fetchQuestionsEmReducer from "./games/games.reducer";
import fetchGroupAccessMappingEmReducer from "./group-access-mapping/groupAccessMapping.reducer";
import fetchAccessGroupEmReducer from "./group-for-auth/authGroup.reducer";
import fetchAccessLvhGroupEmReducer from "./group-for-lvh/lvhGroup.reducer";
import fetchAccessGroupUserEmReducer from "./group-users/groupUsers.reducer";
import fetchGroupEmReducer from "./group/group.reducer";
import guestInvitesEmReducer from "./guest-invites/guestInvites.reducer";
import invitesEmReducer from "./invites/invites.reducer";
import itineraryEmReducer from "./itinerary/itinerary.reducer";
import knowTheHostEmReducer from "./know-the-host/knowTheHost.reducer";
import fetchLeaderBoardUserReducer from "./leader-board-user/leaderBoardUser.reducer";
import liveFeedEmReducer from "./live-feed/liveFeed.reducer";
import fetchAccessLvhGroupUserEmReducer from "./lvh-group-users/lvhGroupUsers.reducer";
import meetingReducer from "./meeting/meeting.reducer";
import meetingHistoryReducer from "./meeting/meetingHistory.reducer";
import fetchNewsAdReducer from "./news/news.reducer";
import permalinkAdReducer from "./permalink/permalink.reducer";
import fetchPoliciesAdReducer from "./policies/policies.reducer";
import fetchPreferenceQuestionsEmReducer from "./prefrences/preferences.reducer";
import readProfileReducer from "./profile-read/readProfile.reducer";
import fetchResidentsAdReducer from "./residents/residents.reducer";
import fetchRSVPDateEmReducer from "./rsvpDate/rsvpDate.reducer";
import fetchTeamEmReducer from "./team/team.reducer";
import fetchTestimonialAdReducer from "./testimonials/testimonial.reducer";
import fetchVenueEmReducer from "./venue/venue.reducer";
import fetchKeywordsEmReducer from "./keywords/keywords.reducer";
import filteredEntityReducer from "./filtered-entities/filtered.entities.reducer";

const appReducer = combineReducers({
  authenticatePhotographer: PhotographerAuthenticationReducer,
  uploadFiles: UploadFilesReducer,

  //v2
  //event manager
  collectionsEm: collectionsEmReducer,
  collectionEm: collectionEmReducer,
  knowTheHostEm: knowTheHostEmReducer,
  invitesEm: invitesEmReducer,
  readProfileEm: readProfileReducer,
  itineraryEm: itineraryEmReducer,
  galleryEm: galleryEmReducer,
  galleryFoldersEm: galleryFoldersEmReducer,
  myPhotosGalleryFoldersEm: myPhotosGalleryFoldersEmReducer,
  liveFeedEm: liveFeedEmReducer,
  guestInvitesEm: guestInvitesEmReducer,
  gamesEm: fetchQuestionsEmReducer,
  venueEm: fetchVenueEmReducer,
  groupEm: fetchGroupEmReducer,
  rsvpDateEm: fetchRSVPDateEmReducer,
  preferenceEm: fetchPreferenceQuestionsEmReducer,
  contactsEm: contactsEmReducer,
  eventPermalinkEm: eventPermalinkEmReducer,
  meetingEm: meetingReducer,
  meetingHistoryEm: meetingHistoryReducer,
  attendanceSummaryMemberEm: attendanceSummaryMemberReducer,
  attendanceSummaryVisitorEm: attendanceSummaryVisitorReducer,
  teamEm: fetchTeamEmReducer,
  attendanceForSingleMemberEm: attendanceForSingleMemberReducer,
  keywordsEm: fetchKeywordsEmReducer,

  //User
  leaderBoardUser: fetchLeaderBoardUserReducer,

  //Admin
  entitiesAd: entitiesAdReducer,
  entityAd: entityAdReducer,
  entityProfileAd: fetchEntityProfileAdReducer,
  testimonialAd: fetchTestimonialAdReducer,
  residentsAd: fetchResidentsAdReducer,
  permalinkAd: permalinkAdReducer,
  accessGroupAd: fetchAccessGroupEmReducer,
  accessGroupUserAd: fetchAccessGroupUserEmReducer,
  groupAccessMappingEm: fetchGroupAccessMappingEmReducer,
  accessLvhGroupEm: fetchAccessLvhGroupEmReducer,
  accessLvhGroupUserEm: fetchAccessLvhGroupUserEmReducer,
  newsAd: fetchNewsAdReducer,
  policiesAd: fetchPoliciesAdReducer,
  filteredAd: filteredEntityReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
