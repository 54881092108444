import React from "react";
import { useParams } from "react-router-dom";
import { size } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import UploadFiles from "../upload-files/UploadFiles";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { setUploadFiles } from "../../../redux/upload-files/uploadFiles.action";
import {
  SECTION_INVITES_COMPONENTS,
  BUCKET_PATH
} from "../../../utils/constant.js";
import { sanitizeFileName } from "../../../utils/utils.js";

const UploadInvites = (props) => {
  const invitesState = useSelector((state) => state.invitesEm);
  const uploadFilesState = useSelector((state) => state.uploadFiles);

  const dispatch = useDispatch();

  const params = useParams();

  const handleDrop = (acceptedFiles) => {
    let lastId = null;
    if (!isEmpty(uploadFilesState.fileProgress)) {
      const ids = Object.keys(uploadFilesState.fileProgress);
      lastId = Math.max(...ids);
    } else if (!isEmpty(invitesState.response.data)) {
      const ids = invitesState.response.data.map((item) => item.sequence);
      lastId = Math.max(...ids);
    }

    const payload = [];
    for (let i = 0; i < size(acceptedFiles); i++) {
      const file = acceptedFiles[i];
      payload.push({
        file: file,
        sequence: lastId ? lastId + i + 1 : i + 1,
        payload: [
          {
            bucket_path: `${BUCKET_PATH.collection}/${params.collectionId}/${
              BUCKET_PATH.invites
            }/${sanitizeFileName(file.name)}`,
            image_name: sanitizeFileName(file.name)
          }
        ]
      });
    }
    dispatch(setUploadFiles(payload));
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent: SECTION_INVITES_COMPONENTS.uploading_invites,
        prevComponent: prevState.currComponent
      };
    });
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Add Invites"
        cancelBtn={true}
        handleCancelBtnClick={props.onCancelClick}
      />
      <div style={{ marginTop: "20%" }}>
        <UploadFiles
          heading="Upload invites here"
          subHeading="JPEG files upto 50MB each"
          buttonLabel="Choose invites to upload"
          handleDropEvent={handleDrop}
        />
      </div>
    </main>
  );
};

export default UploadInvites;
