import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import config from "../../config";
import PermalinkResolver from "../../pages/user/entity/PermalinkResolver";
import { signUpUser } from "../../services/auth.service";
import {
  GUEST_MOBILE,
  ROLE_CATEGORIES,
  STD_ERROR_MESSAGE
} from "../../utils/constant";
import {
  getUserRole,
  isAuthenticated,
  setOriginalUrl,
  storeUserCredentials
} from "../../utils/utils";

const ProtectedRoute = ({
  component: Component,
  redirectPathname: targetPath,
  accessLevel,
  ...rest
}) => {
  const { FIREBASE_ID } = config();
  const [guestAuthentication, setGuestAuthentication] = useState(false);

  useEffect(() => {
    if (isAuthenticated() === false) {
      getComponent();
    }
  });

  const getComponent = () => {
    const payload = {
      firebase_id: FIREBASE_ID,
      category: ROLE_CATEGORIES.other,
      mobile: GUEST_MOBILE
    };
    signUpUser(payload)
      .then((response) => {
        storeUserCredentials({
          authToken: response.data.access_token,
          userId: response.data.user.id,
          userRole: response.data.user.category || ROLE_CATEGORIES.other
        });
        if (accessLevel === getUserRole()) {
          setGuestAuthentication(true);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
        return <Redirect to="/" />;
      });
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (accessLevel === getUserRole()) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        } else {
          setOriginalUrl(props.location?.pathname);
          return <>{guestAuthentication && <Component {...props} />}</>;
        }
      }}
    />
  );
};

export default ProtectedRoute;
