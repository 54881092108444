import React from "react";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import InputBox from "../../common/input-box/InputBox";
import cancelIcon from "../../../assets/icon/cancel.svg";
import { useDispatch } from "react-redux";
import SelectBox from "../../common/select-box/SelectBox";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  ENTITY_TYPES,
  ROLE_CATEGORIES,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { toast } from "react-toastify";
import PhoneInput from "../../common/v2/phone-input/PhoneInput";
import Error from "../../common/v2/error/Error";
import { isValidPhoneNumber } from "react-phone-number-input";

import "./create-entity-popup.css";
import {
  deleteNewUser,
  signUpByEmailAndPassword,
} from "../../../services/firebase-auth.service";
import { deactivateUser, signUpUser } from "../../../services/auth.service";
import {
  createEntity,
  getAllEntities,
} from "../../../services/entities.service";
import { getUserId } from "../../../utils/utils";

const options = [
  {
    value: ENTITY_TYPES.education,
    label: "Education",
  },
  {
    value: ENTITY_TYPES.events_celebrations,
    label: "Events & Celebrations",
  },
  {
    value: ENTITY_TYPES.mnc_hr,
    label: "MNC & HR",
  },
  {
    value: ENTITY_TYPES.food_hospitality,
    label: "Food & Hospitality",
  },
  {
    value: ENTITY_TYPES.marketing,
    label: "Marketing",
  },
  {
    value: ENTITY_TYPES.property,
    label: "Property",
  },
  {
    value: ENTITY_TYPES.health_wellness,
    label: "Health & Wellness",
  },
  {
    value: ENTITY_TYPES.business_owners,
    label: "Business Owners",
  },
  {
    value: ENTITY_TYPES.finance,
    label: "Finance",
  },
  {
    value: ENTITY_TYPES.wedding_planners,
    label: "Wedding Planners",
  },
  {
    value: ENTITY_TYPES.photographers,
    label: "Photographers",
  },
  {
    value: ENTITY_TYPES.bakers_chocolatiers,
    label: "Bakers & Chocolatiers",
  },
  {
    value: ENTITY_TYPES.makeup_stylists,
    label: "Makeup Stylists",
  },
  {
    value: ENTITY_TYPES.resorts,
    label: "Resorts",
  },
  {
    value: ENTITY_TYPES.Caterers,
    label: "Caterers",
  },
  {
    value: ENTITY_TYPES.fashion,
    label: "Fashion",
  },
  {
    value: ENTITY_TYPES.travel,
    label: "Travel",
  },
  {
    value: ENTITY_TYPES.artists,
    label: "Artists",
  },
  {
    value: ENTITY_TYPES.restraunts,
    label: "Restaurants",
  },
  {
    value: ENTITY_TYPES.florists,
    label: "Florists",
  },
  {
    value: ENTITY_TYPES.gifting,
    label: "Gifting",
  },
  {
    value: ENTITY_TYPES.jewelry,
    label: "Jewelry",
  },
];

const CreateEntityPopup = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();

  const initialValues = {
    entity: {
      em_id: "",
      entity_type: "",
      name: "",
    },
  };

  const onSubmit = (values) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    valuesCopy.entity.em_id = getUserId();
    console.log(valuesCopy.entity);
    createEntity(valuesCopy.entity)
      .then((response) => {
        handleClose();
        dispatch(getAllEntities());
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  const validationSchema = Yup.object().shape({
    entity: Yup.object().shape({
      name: Yup.string().required("Please enter the entity name"),
      entity_type: Yup.string().required("Please select the entity type"),
    }),
  });

  return (
    <div className="popup">
      <div className="box">
        <img
          className="close-icon"
          onClick={handleClose}
          src={cancelIcon}
          alt=""
        />
        <h1 className="pb20-lh32 mb-3"> CREATE NEW ENTITY</h1>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Field name="entity.name">
                  {(props) => {
                    const { field, meta } = props;
                    return (
                      <>
                        <InputBox
                          type="text"
                          placeholder="Enter your Entity Name"
                          {...field}
                          errorMsg={
                            meta.touched && meta.error ? meta.error : ""
                          }
                        />
                      </>
                    );
                  }}
                </Field>
                <div className="form-inline">
                  <Field name="entity.entity_type">
                    {(props) => {
                      const { field, meta } = props;
                      return (
                        <>
                          <SelectBox
                            options={options}
                            className="mb-3 col col-sm-6 p-0"
                            placeholder="Entity Type"
                            {...field}
                            errorMsg={
                              meta.touched && meta.error ? meta.error : ""
                            }
                          />
                        </>
                      );
                    }}
                  </Field>
                </div>
                <ButtonAccent
                  type="submit"
                  className="button-w100-mt21 pb14-lh21"
                  label="CREATE ENTITY"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateEntityPopup;
